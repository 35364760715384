export type Order = 'asc' | 'desc'

export type TableOrder<T1> = { orderBy: keyof T1; order: Order }

export function getComparator<T, Key extends keyof T>(
    tableOrderArray: TableOrder<T>[]
): (a: { [key in Key]: unknown }, b: { [key in Key]: unknown }) => number {
    return (a, b) => {
        let compareResult = 0
        tableOrderArray.forEach((element) => {
            const { [element.orderBy]: aValue } = a
            const { [element.orderBy]: bValue } = b
            const direction = element.order
            if (typeof bValue === 'string' || typeof aValue === 'string') {
                // despite what TS says, we can use null and undefined values in localeCompare
                const aString = (aValue as unknown as string | null) || ''
                const bString = (bValue as unknown as string | null) || ''
                compareResult ||= bString.localeCompare(aString) * (direction === 'desc' ? 1 : -1)
            } else if (bValue < aValue) {
                compareResult ||= direction === 'desc' ? -1 : 1
            } else if (bValue > aValue) {
                compareResult ||= direction === 'desc' ? 1 : -1
            }
        })
        return compareResult
    }
}

export function isEqualArray<T>(arr1: (keyof T)[] | undefined, arr2: (keyof T)[] | undefined): boolean {
    return !!arr1 && !!arr2 && arr1.length === arr2.length && arr1.every((element, index) => element === arr2[index])
}
