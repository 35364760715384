import type TableConfig from '../../lib/types/tableConfigTypes'
import React from 'react'
import MuiTable from '@mui/material/Table'
import Skeleton from '@mui/material/Skeleton'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

type LoadingSkeletonProps<T1, T2> = {
    config: TableConfig<T1, T2>
}

const LoadingSkeleton = <T1, T2 = null>({ config }: LoadingSkeletonProps<T1, T2>): JSX.Element => {
    const { fields, size = 'small', loadingSkeleton } = config

    return (
        <TableContainer sx={{ height: loadingSkeleton.height, overflow: 'hidden' }}>
            <MuiTable size={size}>
                <TableHead>
                    <TableRow>
                        {fields.map(
                            (field) =>
                                field.skeletonStyle && (
                                    <TableCell key={field.key}>
                                        <Skeleton sx={{ width: field.skeletonStyle }} />
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array(loadingSkeleton.numOfRows)
                        .fill('')
                        .map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow key={index}>
                                {fields.map(
                                    (field) =>
                                        field.skeletonStyle && (
                                            <TableCell key={field.key}>
                                                <Skeleton sx={{ width: field.skeletonStyle }} />
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    )
}

export default LoadingSkeleton
