import Stack from '@mui/material/Stack'
import React from 'react'
import Typography from '@mui/material/Typography'
import FormattedText from '../FormattedText'
import GameReportPlayerTextSection from './GameReportPlayerTextSection'
import TeamBoxscore from '@/components/games/TeamBoxscore'

type GameContentProps = {
    pregame: string | null
    postgame: string | null
    playerTexts: DTO.GameReportPlayerText[]
    game: DTO.Game | null
    gameBoxscore: DTO.GameBoxscore[]
    team: DTO.Team | null
    playerTags: DTO.PlayerTag[]
    isBoxscoreLoading: boolean
}

type ParseTeam = {
    teamId: string
    teamName: string
    teamMarket: string | null
    teamLogoUrl: string | null
    teamAbbr: string
} | null
export const parseTeam = (
    game: DTO.Game | null | undefined,
    teamId: string | null | undefined,
    type: 'primary' | 'secondary'
): ParseTeam => {
    if (!game || !teamId) return null
    if (type === 'primary') {
        return game.awayTeamId === teamId
            ? {
                  teamId: game.awayTeamId,
                  teamName: game.awayTeamName,
                  teamMarket: game.awayTeamMarket,
                  teamLogoUrl: game.awayTeamLogoUrl,
                  teamAbbr: game.awayTeamAbbr,
              }
            : {
                  teamId: game.homeTeamId,
                  teamName: game.homeTeamName,
                  teamMarket: game.homeTeamMarket,
                  teamLogoUrl: game.homeTeamLogoUrl,
                  teamAbbr: game.homeTeamAbbr,
              }
    }
    return game.awayTeamId !== teamId
        ? {
              teamId: game.awayTeamId,
              teamName: game.awayTeamName,
              teamMarket: game.awayTeamMarket,
              teamLogoUrl: game.awayTeamLogoUrl,
              teamAbbr: game.awayTeamAbbr,
          }
        : {
              teamId: game.homeTeamId,
              teamName: game.homeTeamName,
              teamMarket: game.homeTeamMarket,
              teamLogoUrl: game.homeTeamLogoUrl,
              teamAbbr: game.homeTeamAbbr,
          }
}

const GameContent = ({
    pregame,
    postgame,
    playerTexts,
    game,
    gameBoxscore,
    team,
    playerTags,
    isBoxscoreLoading,
}: GameContentProps): JSX.Element => {
    const primaryTeam = parseTeam(game, team?.teamId, 'primary')
    const secondaryTeam = parseTeam(game, team?.teamId, 'secondary')
    const primaryTeamBoxscoreRows = gameBoxscore.filter((bs) => bs.teamId === team?.teamId)
    const isGameFinished = game?.status === 'FINISHED'
    return (
        <>
            {(!!gameBoxscore.length || isBoxscoreLoading) && primaryTeam && secondaryTeam && (
                <Stack marginBottom={2}>
                    <Typography variant="subtitle2" fontSize="16px" gutterBottom>
                        Box Score
                    </Typography>
                    <TeamBoxscore
                        teamLogoUrl={primaryTeam.teamLogoUrl || null}
                        teamMarket={primaryTeam.teamMarket || null}
                        teamName={primaryTeam.teamName || null}
                        boxScoreRows={primaryTeamBoxscoreRows}
                        isLoading={isBoxscoreLoading}
                    />
                    <TeamBoxscore
                        teamLogoUrl={secondaryTeam.teamLogoUrl || null}
                        teamMarket={secondaryTeam.teamMarket || null}
                        teamName={secondaryTeam.teamName || null}
                        boxScoreRows={gameBoxscore.filter((bs) => bs.teamId !== team?.teamId)}
                        isLoading={isBoxscoreLoading}
                        toggleVisibility
                    />
                </Stack>
            )}
            {isGameFinished && pregame && (
                <Stack marginBottom={2}>
                    <FormattedText label="Pregame Notes" text={pregame} />
                </Stack>
            )}
            <Stack marginBottom={2}>{postgame && <FormattedText label="Postgame Notes" text={postgame} />}</Stack>
            {!!playerTexts.length && (
                <Stack marginBottom={2}>
                    <GameReportPlayerTextSection
                        playerTexts={playerTexts}
                        gameBoxscore={gameBoxscore}
                        playerTags={playerTags}
                        isBoxscoreLoading={isBoxscoreLoading}
                    />
                </Stack>
            )}
        </>
    )
}

export default GameContent
