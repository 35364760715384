export const trimRichText = (text: string | null | undefined): string | null => {
    if (!text) return null
    // Rich text wraps each line in paragraph tags.
    // Remove empty paragraph tags
    const emptyParagraphRegex = /<p class="editor-paragraph"><br><\/p>|<p class="editor-paragraph" dir="ltr"><br><\/p>/

    const regex = new RegExp(emptyParagraphRegex, 'gi')

    let value = text.replace(regex, '')

    value = value.trim()

    if (value === '') return null

    return value
}
