import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import Stack from '@mui/material/Stack'
import FormattedText from '../FormattedText'
import PlayerHeadshotAvatar from '@/components/boards/PositionalBoard/PlayerHeadshotAvatar'
import PlayerGameBoxscore from '@/components/form/PlayerGameBoxscore'
import { overrideStatCategories } from '@/components/widgets/WatchListWidgetPlayerView'

type GameReportPlayerTextProps = {
    playerText: DTO.GameReportPlayerText
    boxscore: DTO.GameBoxscore | null
    playerTag: DTO.PlayerTag | null
    isBoxscoreLoading: boolean
}

type GameReportPlayerTextSectionProps = {
    playerTexts: DTO.GameReportPlayerText[]
    gameBoxscore: DTO.GameBoxscore[] | null
    playerTags: DTO.PlayerTag[]
    isBoxscoreLoading: boolean
}

const GameReportPlayerText = ({
    playerText,
    boxscore,
    playerTag,
    isBoxscoreLoading,
}: GameReportPlayerTextProps): JSX.Element => (
    <Grid container>
        <Grid container item>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                    <PlayerHeadshotAvatar imageUrl={playerTag?.playerHeadshot} height={35} width={35} outlined />
                    <Typography variant="subtitle2" fontSize="16px">
                        {playerTag?.name}
                    </Typography>
                </Box>
            </Grid>
            {(boxscore || isBoxscoreLoading) && (
                <Grid item xs={12} md={8} lg={12}>
                    <PlayerGameBoxscore
                        boxscores={boxscore ? [boxscore] : undefined}
                        isBoxScoreLoading={isBoxscoreLoading}
                        overrideStatCategories={overrideStatCategories}
                        hideSorting
                        hideGameColumn
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormattedText text={playerText.text} />
            </Grid>
        </Grid>
    </Grid>
)

const GameReportPlayerTextSection = ({
    playerTexts,
    gameBoxscore,
    playerTags,
    isBoxscoreLoading,
}: GameReportPlayerTextSectionProps): JSX.Element => (
    <>
        {playerTexts.map((pt, i, arr) => (
            <Stack marginBottom={i === arr.length - 1 ? 0 : 2} key={pt.playerId}>
                <GameReportPlayerText
                    playerText={pt}
                    boxscore={gameBoxscore?.find((bs) => bs.playerId === pt.playerId) || null}
                    playerTag={playerTags.find((t) => t.entityId === pt.playerId) || null}
                    isBoxscoreLoading={isBoxscoreLoading}
                />
            </Stack>
        ))}
    </>
)

export default GameReportPlayerTextSection
