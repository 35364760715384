import type { CSSProperties } from 'react'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import { Box } from '@mui/system'

type FormattedTextProps = {
    label?: string | null
    text?: string
    subCategories?: DTO.TextGrade[]
    intensityGrade?: Enum.IntensityGrade | null
    marginBottom?: boolean
    display?: CSSProperties['display']
}

export const intensityGradeColor = (grade: Enum.IntensityGrade | ''): string => {
    if (grade === 'GREEN') return 'success.main'
    if (grade === 'YELLOW') return 'yellow.main'
    if (grade === 'RED') return 'error.main'
    return ''
}

type SectionHeaderProps = {
    label?: string | null
    intensityGrade?: Enum.IntensityGrade | null
    variant?: 'subtitle2' | 'h6'
    fontSize?: string
    color?: 'black' | 'text.secondary'
}

export const SectionHeader = ({
    label,
    intensityGrade,
    variant = 'subtitle2',
    fontSize = '16px',
    color = 'black',
}: SectionHeaderProps): JSX.Element => (
    <Stack direction="row" alignItems="center" spacing={1.5}>
        {label && (
            <Typography variant={variant} fontSize={fontSize} color={color}>
                {label}
            </Typography>
        )}
        {intensityGrade && (
            <Badge
                variant="dot"
                sx={{
                    '.MuiBadge-dot': {
                        height: 12,
                        width: 12,
                        borderRadius: '6px',
                        border: '1px solid',
                        borderColor: 'grey.200',
                        backgroundColor: intensityGradeColor(intensityGrade),
                    },
                }}
            />
        )}
    </Stack>
)

type SectionTextProps = {
    text: string
    marginBottom: boolean
    display?: CSSProperties['display']
}
export const SectionText = ({ text, marginBottom, display = 'block' }: SectionTextProps): JSX.Element => (
    <Typography
        dangerouslySetInnerHTML={{
            __html: text,
        }}
        variant="body2"
        sx={{
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-wrap',
            '>p,ul': { marginY: 0.5 },
            '> ul': { paddingLeft: 2 },
        }}
        display={display}
        color="text.secondary"
        marginBottom={marginBottom ? 1 : 0}
    />
)

const FormattedText = ({
    label,
    text,
    subCategories,
    intensityGrade,
    marginBottom = true,
    display = 'block',
}: FormattedTextProps): JSX.Element => (
    <>
        <SectionHeader label={label as string} intensityGrade={intensityGrade} />
        {subCategories && (
            <Box sx={{ marginBottom: marginBottom ? 2 : 0 }}>
                {subCategories.map((d) => (
                    <React.Fragment key={d.title}>
                        <SectionHeader label={d.title} intensityGrade={d.grade} fontSize="14px" variant="h6" />
                        {d.text && <SectionText text={d.text} marginBottom />}
                    </React.Fragment>
                ))}
            </Box>
        )}

        {!subCategories && text && <SectionText text={text} marginBottom={marginBottom} display={display} />}
    </>
)

export default FormattedText
