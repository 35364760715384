import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import GroupsIcon from '@mui/icons-material/Groups'
import Button from '@mui/material/Button'
import { Skeleton } from '@mui/material'
import ImageWithFallback from '../ImageWithFallback'

type TeamTableTitleProps = {
    teamLogoUrl: string | null
    teamMarket: string | null
    teamName: string | null
    marginLeft?: string
    isVisible?: boolean
    setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
    isLoading?: boolean
}

const TeamTableTitle = ({
    teamLogoUrl,
    teamMarket,
    teamName,
    marginLeft = '8px',
    isVisible,
    setIsVisible,
    isLoading,
}: TeamTableTitleProps): JSX.Element => (
    <Box>
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', marginLeft }}>
            <Box
                sx={{
                    position: 'relative',
                    height: 24,
                    minWidth: 24,
                }}
            >
                {teamLogoUrl && (
                    <ImageWithFallback src={teamLogoUrl} alt="team logo url" fallbackSize={{ xs: 24 }} priority>
                        <GroupsIcon />
                    </ImageWithFallback>
                )}
            </Box>
            {isLoading ? (
                <Skeleton width={100} height={30} />
            ) : (
                <Typography color="text.primary" variant="h6" fontSize="16px">
                    {teamMarket} {teamName}
                </Typography>
            )}

            {setIsVisible && (
                <Button variant="text" onClick={() => setIsVisible(!isVisible)} size="small">
                    {isVisible ? 'Hide' : 'Show'}
                </Button>
            )}
        </Box>
    </Box>
)

export default TeamTableTitle
