import type { Select } from '../../lib/types/tableConfigTypes'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

type ExpandTableRowButtonProps = {
    collapsedRowOpenIdx: number | undefined
    setCollapsedRowOpenIdx: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ExpandTableRowButton =
    <T1, T2>({ collapsedRowOpenIdx, setCollapsedRowOpenIdx }: ExpandTableRowButtonProps): Select<T1, T2> =>
    (_, idx, tableProps): JSX.Element => {
        const isOpen = idx === collapsedRowOpenIdx
        return (
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setCollapsedRowOpenIdx(isOpen ? undefined : idx)}
                sx={{
                    border: 'none',
                    visibility: tableProps.isRowExpandable ? 'visible' : 'hidden',
                    padding: '0px',
                    height: '20px',
                }}
            >
                {idx === collapsedRowOpenIdx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        )
    }

export default ExpandTableRowButton
