import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import { formatDateString } from '@/lib/utils/formatters'

type TimeLocationDetailsProps = {
    event:
        | (Pick<DTO.AssignmentCalendarExtendedProps, 'venue' | 'gameTimeUtc'> & {
              date: string | null
          })
        | undefined
    isLoading: boolean
}

const TimeLocationDetails = ({ event, isLoading }: TimeLocationDetailsProps): JSX.Element => (
    <>
        <Box
            sx={{
                display: { xs: 'flex', md: 'none' },
                marginY: 'auto',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                bottom: '8px',
            }}
        >
            {event?.date && (
                <Typography color="text.secondary" variant="overline" textAlign="center" lineHeight={1}>
                    {formatDateString(event.gameTimeUtc || event.date, 'M/D/YY', 'eastern')}
                </Typography>
            )}
            <Typography fontWeight="bold" fontSize={18}>
                &#64;
            </Typography>
        </Box>
        <Box
            sx={{
                display: { xs: 'none', md: 'flex' },
                order: { xs: 1, sm: 0 },
                marginX: { xs: 'auto', sm: 'initial' },
                justifyContent: 'center',
                width: { xs: 'initial', sm: 200, md: 300 },
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                }}
            >
                {isLoading && <Skeleton height={10} width={50} />}
                {!isLoading && event?.date && (
                    <Typography
                        color="text.secondary"
                        variant="subtitle2"
                        textAlign="center"
                        position={{ xs: 'initial', sm: 'absolute' }}
                        top={{ xs: 'initial', sm: 12 }}
                        fontSize={12}
                    >
                        {formatDateString(event.gameTimeUtc || event.date, 'MMMM D', 'eastern')}
                    </Typography>
                )}
                {isLoading && <Skeleton height={10} width={50} />}
                {!isLoading && event?.venue?.name && (
                    <Typography
                        color="text.secondary"
                        variant="subtitle2"
                        textAlign="center"
                        textOverflow="ellipsis"
                        maxWidth={{ xs: 'initial', sm: 100, md: 150 }}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        position={{ xs: 'initial', sm: 'absolute' }}
                        top={{ xs: 'initial', sm: 30 }}
                        fontSize={12}
                    >
                        {event.venue.name}
                    </Typography>
                )}
            </Box>
            {(isLoading || (event?.date && event.gameTimeUtc)) && (
                <Divider
                    orientation="vertical"
                    sx={{ marginY: 'auto', marginX: 1, height: { xs: '95%', md: '70%' } }}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                {isLoading && <Skeleton height={10} width={50} />}
                {!isLoading && event?.gameTimeUtc && (
                    <Typography
                        color="text.secondary"
                        variant="subtitle2"
                        textAlign="center"
                        position={{ xs: 'initial', sm: 'absolute' }}
                        top={{ xs: 'initial', sm: 12 }}
                        fontSize={12}
                    >
                        {formatDateString(event.gameTimeUtc, 'h:mma z', 'local')}
                    </Typography>
                )}
                {isLoading && <Skeleton height={10} width={50} />}
                {!isLoading && event?.venue?.city && event.venue.stateProvince && (
                    <Typography
                        color="text.secondary"
                        variant="subtitle2"
                        textAlign="center"
                        position={{ xs: 'initial', sm: 'absolute' }}
                        top={{ xs: 'initial', sm: 30 }}
                        fontSize={12}
                    >
                        {event.venue.city}, {event.venue.stateProvince}
                    </Typography>
                )}
            </Box>
        </Box>
    </>
)

export default TimeLocationDetails
