import type { TableConfigField } from '@/lib/types/tableConfigTypes'
import type TableConfig from '@/lib/types/tableConfigTypes'
import React, { useState } from 'react'
import { boxscoreFields, type OptionalGameBoxscore } from '../form/PlayerGameBoxscore'
import Table from '../Table'
import Link from '../Link'
import TeamTableTitle from './TeamTableTitle'
import { horizontalStickyStyles } from '@/lib/utils/sticky'
import { useBreakPoints } from '@/lib/hooks'

type TeamBoxscoreProps = {
    teamLogoUrl: string | null
    teamMarket: string | null
    teamName: string | null
    boxScoreRows: OptionalGameBoxscore[] | null
    isLoading: boolean
    toggleVisibility?: boolean
}

const boxScoreTableConfig = (isMobile: boolean): TableConfig<OptionalGameBoxscore> => {
    const fields: TableConfigField<OptionalGameBoxscore>[] = [
        {
            key: 'playerName',
            label: 'Player',
            select: (x) => (
                <Link
                    sx={{ '@media print': { color: 'text.primary', fontSize: '18px' } }}
                    href={`/players/${x.playerUrlSlug}/feed`}
                >
                    {isMobile ? `${x.playerName[0]}. ${x.playerName.split(' ')[1] || ''}` : x.playerName}
                </Link>
            ),
            sortColumn: 'playerName',
            headCellFormat: { whiteSpace: 'nowrap', ...horizontalStickyStyles },
            format: { whiteSpace: 'nowrap', ...horizontalStickyStyles },
            skeletonStyle: { xs: 30, sm: 75 },
        },
        ...boxscoreFields().filter((f) => f.key !== 'gameName' && f.key !== 'gp' && f.key !== 'gs'),
    ]
    return {
        fields,
        loadingSkeleton: {
            numOfRows: 15,
            height: 500,
        },
    }
}

export const boxscoreSort = (a: OptionalGameBoxscore, b: OptionalGameBoxscore): number =>
    (b.gp ? 1 : 0) - (a.gp ? 1 : 0) ||
    (b.gs ? 1 : 0) - (a.gs ? 1 : 0) ||
    (b.min || 0) - (a.min || 0) ||
    (b.dnpReason === null ? 1 : -1) - (a.dnpReason === null ? 1 : -1) ||
    (a.dnpReason || '').localeCompare(b.dnpReason || '')

const TeamBoxscore = ({
    teamLogoUrl,
    teamMarket,
    teamName,
    boxScoreRows,
    isLoading,
    toggleVisibility = false,
}: TeamBoxscoreProps): JSX.Element => {
    const [isVisible, setIsVisible] = useState(!toggleVisibility)
    const { isMobile } = useBreakPoints()
    return (
        <>
            <TeamTableTitle
                teamLogoUrl={teamLogoUrl}
                teamMarket={teamMarket}
                teamName={teamName}
                isVisible={isVisible}
                setIsVisible={toggleVisibility ? setIsVisible : undefined}
            />
            {isVisible && (
                <Table<OptionalGameBoxscore>
                    rows={boxScoreRows?.sort((a, b) => boxscoreSort(a, b)) || []}
                    isLoading={isLoading}
                    config={boxScoreTableConfig(isMobile)}
                    getRowKey={(row) => row.playerId}
                    hover={false}
                    hideSortIcon
                />
            )}
        </>
    )
}

export default TeamBoxscore
